import 'sportic-common/resources/assets/scripts/frontend';

var $ = require("jquery");

window.$ = $;
window.jQuery = $;
global.$ = $;
global.jQuery = $;
jQuery = $;

// $(document).ready(function () {
//     $('[data-toggle="tooltip"]').tooltip();
//     $('[data-toggle="popover"]').popover();
//     $('#content').bootstrapMaterialDesign();
// });
